import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import { getlocal, getStorage } from "../../../functions/localstorage";
import {
  baseurl,
  ErrorAlert,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UploadFile,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { FaArrowRight, FaPenNib, FaTruckLoading } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { URL_Account_Journal_Create } from "../../../Constancs/apiconst";

function FormHeader({
  State = "New",
  FormName = "",
  FormNumber = "",
  Actions = [],
}) {
  return (
    <div className="flex items-center justify-between bg-secondary h-[38px] rounded-lg p-1 shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] ">
      <div className="flex gap-3 h-full items-center">
        <span className=" text-[14px] border flex items-center px-1 py-0.5 rounded-lg">
          {State}
        </span>
        <span className="text-[14px]  flex flex-col">
          <span className="text-[12px] font-bold text-Ocean_Blue">
            {FormName}
          </span>
          <span className="text-[14px] font-semibold ">{FormNumber}</span>
        </span>
      </div>
      <div className="flex gap-3 h-full items-center mr-8">
        {Actions.map((item, index) => (
          <button
            key={index}
            className={
              "border px-2 py-1 rounded-lg font-semibold hover:bg-tertiary " +
              (item.type === "primary"
                ? " bg-Ocean_Blue text-secondary "
                : " bg-Alice_Blue ")
            }
            onClick={item.onClick}
          >
            {item.ButtonName}
          </button>
        ))}
      </div>
    </div>
  );
}

function FormStage({ Stages = [] }) {
  return (
    <div className="flex items-center justify-center h-[38px] gap-3">
      {Stages.map((stage, index) => (
        <div key={index} className=" flex gap-1 items-center">
          <span
            className={
              " border p-1 rounded-full text-[14px] " +
              (stage.selected
                ? " bg-Ocean_Blue text-secondary "
                : stage.completed
                ? " bg-[#3ec95c] text-secondary "
                : " ")
            }
          >
            {stage.completed ? <TiTick /> : stage.icon}
          </span>
          <h3 className="text-[14px] pr-3">{stage.Name}</h3>
          {index < Stages.length - 1 ? <FaArrowRight /> : ""}
        </div>
      ))}
    </div>
  );
}

const JournalsForm = () => {
  const { option, journalId, companyId } = useParams();
  const navigate = useNavigate();
  const getrecord = `/users/users/${journalId}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();
  let storage = getStorage();
  // *initial values
  const init = {
    proj_id: 0,
    cust_id: 0,
    journal_no: "JNL/24-25/0176",
    debitAccountId: "",
    creditAccountId: "",
    date: "",
    currency: "inr",
    amount: "",
    description: "Notes: ",
    attachment: "",
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    journal_no: {},
    debitAccountId: {
      debitAccountId_list: [{ value: "", label: "Please Choose" }],
    },
    creditAccountId: {
      creditAccountId_list: [{ value: "", label: "Please Choose" }],
    },
    date: {},
    reference_Number: {},
    currency: {
      currency_list: [{ label: "INR-Indian Currency", value: "inr" }],
    },
    amount: {},
    description: {},
    attachment: {},
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  useEffect(() => {
    const localprops = getlocal("properties")?.companies || {};

    console.log("JournalFoem for life ", companyId);

    HandleMultiPropsChange(localprops);

    let group = [{ value: "", label: "Please Choose" }];
    storage.group.map((item, index) => {
      group.push({ label: item.name, value: index });
    });
    HandlePropsChange("debitAccountId", {
      debitAccountId_list: group,
    });
    HandlePropsChange("creditAccountId", {
      creditAccountId_list: group,
    });

    // console.log("Params",param);
    if (journalId !== undefined) {
      let journal = storage.journal;
      handleMultiFieldChange(journal[journalId]);
      // store.setheader(option.toUpperCase() + " Company");
      // HandlePropsChange("company_status", { hidden: false });
      // if (option === "view") {
      //   HandleMultiPropsChange({
      //     company_logo: { readonly: true },
      //     company_name: { readonly: true },
      //     company_address_line1: { readonly: true },
      //     company_address_line2: { readonly: true },
      //     company_address_city: { readonly: true },
      //     company_address_pincode: { readonly: true },
      //     company_address_state: { readonly: true },
      //     company_phone_alternative: { readonly: true },
      //     company_website_url: { readonly: true },
      //     company_phone: { readonly: true },
      //     company_email: { readonly: true },
      //     company_status: { readonly: true },
      //   });
      // } else {
      //   HandleMultiPropsChange({
      //     company_logo: { readonly: false },
      //     company_name: { readonly: false },
      //     company_address_line1: { readonly: false },
      //     company_address_line2: { readonly: false },
      //     company_address_city: { readonly: false },
      //     company_address_pincode: { readonly: false },
      //     company_address_state: { readonly: false },
      //     company_phone_alternative: { readonly: false },
      //     company_website_url: { readonly: false },
      //     company_phone: { readonly: false },
      //     company_email: { readonly: false },
      //     company_status: { readonly: false },
      //   });
      // }
      // GetRecords(getrecord).then((res) => {
      //   console.log("Companies Data - ", res, res.record);
      //   if (res.success) {
      //     handleMultiFieldChange(res.record);
      //   } else if (res.code === 401) {
      //     store.logout();
      //     store.navTo("/login");
      //   }
      // });
    } else {
      store.setheader("CREATE NEW Company");
      // props for the Mandatory field
      // HandleMultiPropsChange({
      //   voucher: { mandatory: true },
      //   type: { hidden: true, readonly: true },
      //   date: { hidden: true },
      //   group: { hidden: true },
      //   // journal_no: { readonly: true },
      //   reference_Number: { hidden: true },
      //   description: { hidden: true },
      //   attachment: { hidden: true },
      //   journal_Type: { hidden: true },
      //   amount: { hidden: true },
      //   currency: { hidden: true, readonly: true },
      // });
    }
  }, [option, journalId]);

  // *print the Record and Props if any value change between the Record and Props
  // useEffect(() => {
  //   console.log("Record State change -> ", Record);
  //   console.log("Props State change -> ", props);
  // }, [Record]);

  // *create Or update the Record
  function update() {}
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      store.stopLoading();

      // savedata = {
      //   debitAccountId: savedata.debitAccountId,
      //   creditAccountId: savedata.creditAccountId,
      //   company_id: companyId,
      //   amount: savedata.amount,
      //   description: savedata.description,
      //   createdBy: savedata.createdBy + "",
      // };

      saveRecord(URL_Account_Journal_Create, savedata).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("User Submitted Successfully");
          store.navback();
        } else {
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data?.errorMessage ||
              " Error Found Please contact the Admin"
          );
        }
      });
    }
  }

  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }

  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  // *Handle Change in the fields
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    UploadFile(attachment_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment", res.data);
      }
    });
  }

  return (
    <AccountsLayout HeaderTitle={"Journal Form"}>
      <div className="flex flex-col h-full text-[12px] gap-1">
        <FormHeader
          State="Draft"
          FormName="Journal"
          FormNumber={Record.journal_no}
          Actions={[
            {
              ButtonName: "Confirm",
              type: "primary",
              onClick: Submit,
            },
            {
              ButtonName: "Cancel",
              onClick: () => {
                navigate(-1);
              },
            },
          ]}
        />
        {/* <FormStage Stages={props.Stages.Stages_list} /> */}
        <div className="flex bg-secondary flex-col items-center flex-grow rounded-lg shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] overflow-auto">
          <div className="grid grid-cols-2 gap-2 w-[80%] mt-5">
            <div // debitAccountId
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.debitAccountId.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                From Account
                {props.debitAccountId.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"debitAccountId"}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.debitAccountId.mandatory && props.debitAccountId.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("debitAccountId", event.target.value);
                }}
                value={Record.debitAccountId}
                disabled={props.debitAccountId.readonly}
              >
                {props.debitAccountId.debitAccountId_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div // date
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.date.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Date
                {props.date.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
                  (props.date.mandatory && props.date.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"date"}
                type="date"
                value={Record.date.split("T")[0]}
                disabled={props.date.readonly}
                onChange={(event) => {
                  handleFieldChange("date", event.target.value + "T00:00:00Z");
                }}
              />
            </div>
            <div // creditAccountId
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.creditAccountId.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                To Account
                {props.creditAccountId.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"creditAccountId"}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.creditAccountId.mandatory && props.creditAccountId.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("creditAccountId", event.target.value);
                }}
                value={Record.creditAccountId}
                disabled={props.creditAccountId.readonly}
              >
                {props.creditAccountId.creditAccountId_list.map(
                  (item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                )}
              </select>
            </div>
            <div // currency
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.currency.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                currency
                {props.currency.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"currency"}
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.currency.mandatory && props.currency.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("currency", event.target.value);
                }}
                value={Record.currency}
                disabled={props.currency.readonly}
              >
                {props.currency.currency_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div // amount
              className={
                "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.amount.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Amount
                {props.amount.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.amount.mandatory && props.amount.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"amount"}
                type="text"
                value={Record.amount}
                disabled={props.amount.readonly}
                onChange={(event) => {
                  if (Number(event.target.value).toString() !== "NaN")
                    handleFieldChange("amount", event.target.value);
                }}
              />
            </div>
            <div // attachment
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.attachment.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Attachement
                {props.attachment.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <button
                className="border px-2 py-1 rounded-lg flex gap-1 items-center"
                onClick={() => {
                  document.getElementById("FileInput").click();
                }}
              >
                <MdOutlineFileUpload className="text-[#20b25b] text-[18px] font-bold" />
                Upload File
              </button>
              {Record.attachment && (
                <a
                  className="cursor-pointer hover:text-[#3f4fff] hover:bg-[#2a3cff] hover:bg-opacity-15 px-2 py-1 rounded-lg flex items-center gap-1 group transition-all duration-100"
                  href={baseurl + "/files/preview?key=" + Record.attachment}
                  target="_blank"
                >
                  {Record.attachment.replaceAll("Journal_/", "")}
                  <FaRegEye className="group-hover:block hidden transition-all duration-75" />
                </a>
              )}
              <input
                type="file"
                onChange={HandleAttachement}
                id="FileInput"
                className="hidden"
              />
            </div>
            <div // description
              className={
                "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1 col-span-2 " +
                (props.description.hidden ? " hidden " : " flex ")
              }
            >
              <textarea
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                  (props.description.mandatory && props.description.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"description"}
                value={Record.description}
                disabled={props.description.readonly}
                onChange={(event) => {
                  if (event.target.value.length > 7) {
                    handleFieldChange("description", event.target.value);
                  } else {
                    handleFieldChange("description", "Notes: ");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default JournalsForm;
