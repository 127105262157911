import React, { useEffect } from "react";
import AccountsLayout from "../AccountsLayout";
import { useLocation, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import {
  DeleteRecord,
  GetListview,
  SuccessAlert,
  URLPRIFIX,
  WarningAlert,
} from "../../../functions/api";
import Filters from "../../../Components/Fields/Filters";
import ListView from "../../../Components/Elements/ListView";
import Pagination from "../../../Components/Fields/Pagination";
import { getStorage } from "../../../functions/localstorage";

const LedgerList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { companyId } = useParams();
  const tablename = "companies";
  const tableURL = "/api/v2/ChartsOfAccounts/getallchartsofaccounts";
  const DeleteURL = "/api/v2/ChartsOfAccounts/deletechartsofaccounts/";
  let storage = getStorage();
  const Header = [
    ["Ledger Name", "ledger_name"],
    ["Group", "parent_name"],
    ["Opening Balance", "opening_balance"],
    ["Action", "ACTION"],
  ];
  const store = useStore();
  const init = {
    filterfield: "all",
    search: "",
    sort: "desc",
    currentpage: 1,
    totalpage: 1,
  };

  useEffect(() => {
    store.setmodule("tasks", init).then((res) => {
      store.stopLoading();
      store.setheader(tablename + " List");
      GetListview({
        listUrl: tableURL,
        store: store,
        field: queryParams.get("field") || init.filterfield,
        search: queryParams.get("search") || init.search,
        sort: queryParams.get("sort") || init.sort,
      });
    });
  }, []);

  function change(props) {
    console.log("search data - ", props);
    GetListview({
      listUrl: tableURL,
      store: store,
      field: props.field,
      search: props.search,
      sort: props.sort,
    });
  }
  function onpage(props) {
    console.log("Pagination number - ", props);
    GetListview({
      listUrl: tableURL,
      store: store,
      field: store.getvalue("filterfield"),
      search: store.getvalue("search"),
      sort: store.getvalue("sort"),
      page: props,
    });
  }

  return (
    <AccountsLayout HeaderTitle={"Charts of Accounts"}>
      <div className="grid grid-rows-10 h-full ">
        <div className="row-span-1 relative">
          <Filters fields={Header} onchange={change} />
          <div className="absolute right-10 top-0">
            <button
              className="bg-primary text-secondary py-2 text-[12px] px-6 rounded-3xl"
              onClick={() => {
                store.navTo(
                  "/" + URLPRIFIX + "/" + companyId + "/accounts/ledger/create"
                );
              }}
            >
              Add Ledger
            </button>
          </div>
        </div>
        <div className="row-span-9 h-full w-full ">
          <div
            className="py-5 px-[30px] h-full max-2xl:w-[1000px] w-full"
            contentEditable={false}
          >
            <ListView
              width="1080px"
              header={Header}
              records={store.getvalue("list")}
              // records={list}
              onedit={
                store.getprops("update")
                  ? (index) => {
                      store.navTo(
                        `/${URLPRIFIX}/${companyId}/accounts/ledger/edit/${index}`
                      );
                    }
                  : undefined
              }
              onview={
                store.getprops("view")
                  ? (index) => {
                      store.navTo(
                        `/${URLPRIFIX}/${companyId}/accounts/ledger/view/${index}`
                      );
                    }
                  : undefined
              }
              ondelete={
                store.getprops("delete")
                  ? (index) => {
                      DeleteRecord(DeleteURL + index).then((res) => {
                        if (res.success) {
                          SuccessAlert("Deleted Successfully");
                          GetListview({
                            listUrl: tableURL,
                            store: store,
                            field: store.getvalue("filterfield"),
                            search: store.getvalue("search"),
                            sort: store.getvalue("sort"),
                            page: store.getvalue("currentpage"),
                          });
                        } else {
                          WarningAlert("Unable to delete Record");
                        }
                      });
                    }
                  : undefined
              }
            />
            <div className="float-right mr-8 mt-6">
              <Pagination onchange={onpage} />
            </div>
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default LedgerList;
