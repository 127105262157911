import React, { useEffect } from "react";
import AccountsLayout from "../AccountsLayout";
import { useLocation, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import {
  DeleteRecord,
  GetListview,
  GetRecords,
  SuccessAlert,
  URLPRIFIX,
} from "../../../functions/api";
import Filters from "../../../Components/Fields/Filters";
import { getlocal, getStorage } from "../../../functions/localstorage";
import Pagination from "../../../Components/Fields/Pagination";

const ListView = ({
  width = "100%",
  height = "90%",
  header = [],
  records = [],
  onedit,
  onview,
  ondelete,
  onclick,
  Tablehead = "text-[14px] w-[220px]",
  Tablerecord = "text-[14px] w-[220px]",
}) => {
  return (
    <div
      className={`flex  overflow-auto `}
      style={{ height: height, width: width }}
    >
      <table className="table-auto w-full h-fit">
        <thead className="sticky top-0 bg-secondary text-left mt-10 border-b">
          <tr className="px-1">
            {header.map(([name, label], index) => {
              if (label !== "id") {
                return (
                  <th key={index} className={Tablehead}>
                    {name}
                  </th>
                );
              }
            })}
          </tr>
        </thead>

        <tbody>
          {records.map((item, index) => (
            <tr
              key={index}
              className={
                "border-b h-8 hover:bg-[#b3b3f5] rounded-xl " +
                (item.error && ` bg-red bg-opacity-35 `)
              }
            >
              {header.map(([name, label], fieldindex) => {
                // console.log("Listview actioon",field == "ACTION" && (ondelete!=undefined||onview!=undefined||onedit!=undefined),(ondelete!=undefined||onview!=undefined||onedit!=undefined));

                if (label == "ACTION") {
                  return (
                    <td key={fieldindex} className={Tablerecord}>
                      <div className="flex gap-7">
                        {onedit && (
                          <img
                            src="/Assets/images/pencil.png"
                            className=" cursor-pointer w-5 h-5 hover:scale-125"
                            onClick={() => {
                              onedit(item["id"]);
                            }}
                          />
                        )}
                        {onview && (
                          <img
                            src="/Assets/images/eye.png "
                            className=" cursor-pointer w-5 h-5 hover:scale-125"
                            onClick={() => {
                              onview(item["id"]);
                            }}
                          />
                        )}
                        {ondelete && (
                          <img
                            src="/Assets/images/trash.png"
                            className=" cursor-pointer w-5 h-5 hover:scale-125"
                            onClick={() => {
                              const confirmDelete = window.confirm(
                                "Are you sure you want to delete?"
                              );
                              if (confirmDelete) {
                                ondelete(item["id"]);
                              }
                            }}
                          />
                        )}
                      </div>
                    </td>
                  );
                } else if (label != "id") {
                  return (
                    <td
                      key={fieldindex}
                      className={Tablerecord}
                      onClick={() => {
                        onclick && onclick(item["id"]);
                      }}
                    >
                      {item[label]}
                    </td>
                  );
                } else {
                  <td key={fieldindex} className={Tablerecord}>
                    ---
                  </td>;
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const GroupList = () => {
  const location = useLocation();
  const { companyId } = useParams();
  const queryParams = new URLSearchParams(location.search);

  const tablename = "companies";
  const tableURL = "/api/v2/accounting/getallaccountcore";
  const DeleteURL = "/api/v2/accounting/deleteaccountcore/";
  let storage = getStorage();
  const Header = [
    ["Group Name", "account_core_name"],
    ["Parent", "parent_name"],
    ["Created Date", "created_date"],
    ["Action", "ACTION"],
  ];

  const store = useStore();
  const init = {
    filterfield: "all",
    search: "",
    sort: "desc",
    currentpage: 1,
    totalpage: 1,
  };

  useEffect(() => {
    store.setmodule("tasks", init).then((res) => {
      store.stopLoading();
      store.setheader(tablename + " List");
      GetListview({
        listUrl: tableURL,
        store: store,
        field: queryParams.get("field") || init.filterfield,
        search: queryParams.get("search") || init.search,
        sort: queryParams.get("sort") || init.sort,
      });
    });
  }, []);

  function change(props) {
    console.log("search data - ", props);
    GetListview({
      listUrl: tableURL,
      store: store,
      field: props.field,
      search: props.search,
      sort: props.sort,
    });
  }
  function onpage(props) {
    console.log("Pagination number - ", props);
    GetListview({
      listUrl: tableURL,
      store: store,
      field: store.getvalue("filterfield"),
      search: store.getvalue("search"),
      sort: store.getvalue("sort"),
      page: props,
    });
  }

  return (
    <AccountsLayout HeaderTitle={"Account Groups"}>
      <div className="grid grid-rows-10 h-full ">
        <div className="row-span-1 relative">
          {store.getprops("add") && (
            <div className="absolute right-10 top-0">
              <button
                className="bg-primary text-secondary py-2 text-[12px] px-6 rounded-3xl"
                onClick={() => {
                  store.removemodule(
                    tablename.substring(0, tablename.length - 1)
                  );
                  store.navTo(
                    "/" + URLPRIFIX + "/" + companyId + "/accounts/group/create"
                  );
                }}
              >
                Add Group
              </button>
            </div>
          )}
          <Filters fields={Header} onchange={change} />
        </div>
        <div className="row-span-9 h-full w-full ">
          <div
            className="py-5 px-[30px] h-full max-2xl:w-[1000px] w-full"
            contentEditable={false}
          >
            <ListView
              width="1080px"
              header={Header}
              records={store.getvalue("list")}
              onedit={
                store.getprops("update")
                  ? (index) => {
                      store.navTo(
                        `/${URLPRIFIX}/${companyId}/accounts/group/edit/${index}`
                      );
                    }
                  : undefined
              }
              onview={
                store.getprops("view")
                  ? (index) => {
                      store.navTo(
                        `/${URLPRIFIX}/${companyId}/accounts/group/view/${index}`
                      );
                    }
                  : undefined
              }
              ondelete={
                store.getprops("delete")
                  ? (index) => {
                      DeleteRecord(DeleteURL + index, {
                        modifiedBy: store.getuser().id,
                      }).then((res) => {
                        console.log("DeleteRecord", res);

                        if (res.success) {
                          SuccessAlert("Deleted Successfully");
                          GetListview({
                            listUrl: tableURL,
                            store: store,
                            field: store.getvalue("filterfield"),
                            search: store.getvalue("search"),
                            sort: store.getvalue("sort"),
                            page: store.getvalue("currentpage"),
                          });
                        }
                      });
                    }
                  : undefined
              }
            />
            <div className="float-right mr-8 mt-6">
              <Pagination onchange={onpage} />
            </div>
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default GroupList;
