import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import { getlocal } from "../../../functions/localstorage";
import {
  baseurl,
  ErrorAlert,
  GetDateTimeFormat,
  GetRecords,
  UploadFile,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";

const ProfitLoss = () => {
  const { companyId } = useParams();

  const navigate = useNavigate();
  // const getrecord = `/users/users/${id}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();

  // *initial values
  const init = {
    revenues: [],
    expenses: [],
    totalRevenue: "",
    totalExpenses: "0",
    netProfit: "0",

    fromDate: "",
    ToDate: "",
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    revenues: {},
    expenses: {},
    totalRevenue: {},
    totalExpenses: {},
    netProfit: {},

    fromDate: {},
    ToDate: {},
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  useEffect(() => {
    const dateTime = GetDateTimeFormat();

    handleMultiFieldChange({
      fromDate: dateTime.MonthStartDate + "Z",
      ToDate: dateTime.MonthEndDate + "Z",
    });

    loadRecord();
  }, []);

  function loadRecord(fromDate, ToDate) {
    GetRecords(
      `/api/v2/accounting/profitandloss/${companyId}?startDate=${
        fromDate || Record.fromDate
      }&endDate=${ToDate || Record.ToDate}`
    ).then((res) => {
      console.log("accounting/balancesheet", res);
      if (res.success) {
        res = res.record;
        handleMultiFieldChange({
          revenues: res.revenues,
          expenses: res.expenses,
          totalRevenue: res.totalRevenue,
          totalExpenses: res.totalExpenses,
          netProfit: res.netProfit,
        });
      }
    });
  }

  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record, props]);

  // *create Or update the Record
  function update() {}
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      store.stopLoading();
      localStorage.setItem("journal_Number", Record.journal_Number + 1);

      //   saveRecord(setrecord, savedata).then((res) => {
      //     console.log("Resonce of saveRecord - ", res);
      //     if (res.success) {
      //       store.stopLoading();
      //       SuccessAlert("User Submitted Successfully");
      //       store.navback();
      //     } else {
      //       store.stopLoading();
      //       ErrorAlert(
      //         res?.errormessage?.response?.data?.errorMessage ||
      //           " Error Found Please contact the Admin"
      //       );
      //     }
      //   });
    }
  }

  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }

  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  // *Handle Change in the fields
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    UploadFile(attachment_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment", res.data);
      }
    });
  }

  return (
    <AccountsLayout HeaderTitle={"Profit and Loss"}>
      <div className="flex px-10 pt-1 bg-Alice_Blue rounded-lg ">
        <div // fromDate
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.fromDate.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            From Date
            {props.fromDate.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
              (props.fromDate.mandatory && props.fromDate.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"fromDate"}
            type="date"
            value={Record.fromDate.split("T")[0]}
            disabled={props.fromDate.readonly}
            onChange={(event) => {
              handleFieldChange("fromDate", event.target.value + "T00:00:00Z");
              loadRecord(event.target.value + "T00:00:00Z", Record.ToDate);
            }}
          />
        </div>
        <div // ToDate
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.ToDate.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            To Date
            {props.ToDate.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
              (props.ToDate.mandatory && props.ToDate.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"ToDate"}
            type="date"
            value={Record.ToDate.split("T")[0]}
            disabled={props.ToDate.readonly}
            onChange={(event) => {
              handleFieldChange("ToDate", event.target.value + "T00:00:00Z");
              loadRecord(Record.fromDate, event.target.value + "T00:00:00Z");
            }}
          />
        </div>
      </div>
      <div className="flex h-[85%] px-10 pt-5 bg-Alice_Blue rounded-lg">
        <div className="w-[50%] p-2 ">
          <div className="h-fit">
            <h1 className="font-bold text-[18px]">Revenue</h1>
            <div className=" h-[90%] overflow-y-auto p-1">
              {Record.revenues.map((item, index) => {
                if (item.amount != 0) {
                  return (
                    <div key={index}>
                      {item.accountName + " : " + item.amount}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="h-[5%] font-semibold ">
            {"Total Revenue : " + Record.totalRevenue}
          </div>
        </div>
        <div className="w-[50%] p-2 ">
          <div className="h-fit">
            <h1 className="font-bold text-[18px]">Expense</h1>
            <div className=" h-[90%] overflow-y-auto p-1">
              {Record.expenses.map((item, index) => {
                if (item.amount != 0) {
                  return (
                    <div key={index} className="pl-2">
                      {item.accountName + " : " + item.amount}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="h-[5%] font-semibold ">
            {"Total Expense: " + Record.totalExpenses}
          </div>
        </div>
      </div>
      <div className="text-[16px] font-semibold px-12 ">
        {" "}
        {"Net profit: " + Record.netProfit}
      </div>
    </AccountsLayout>
  );
};

export default ProfitLoss;
