import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import { getlocal } from "../../../functions/localstorage";
import {
  baseurl,
  ErrorAlert,
  GetDateTimeFormat,
  GetRecords,
  UploadFile,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";

const GeneralLedger = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const getrecord = `/users/users/${companyId}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();

  // *initial values
  const init = {
    entries: [],
    totalDebit: [],
    totalCredit: [],
    fromDate: "",
    ToDate: "",
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    entries: {},
    totalDebit: {},
    totalCredit: {},
    fromDate: {},
    ToDate: {},
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  useEffect(() => {
    const dateTime = GetDateTimeFormat();

    handleMultiFieldChange({
      fromDate: dateTime.MonthStartDate,
      ToDate: dateTime.MonthEndDate,
    });

    loadRecord(dateTime.MonthStartDate + "Z", dateTime.MonthEndDate + "Z");
  }, []);

  function loadRecord(fromDate, ToDate) {
    GetRecords(
      `/api/v2/accounting/trialbalance/${companyId}?startDate=${
        fromDate || Record.fromDate
      }&endDate=${ToDate || Record.ToDate}`
    ).then((res) => {
      console.log("accounting/trialbalance", res);
      if (res.success) {
        res = res.record;
        handleMultiFieldChange({
          entries: res.entries,
          totalDebit: res.totalDebit,
          totalCredit: res.totalCredit,
        });
      }
    });
  }

  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record, props]);

  // *create Or update the Record
  function update() {}
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      store.stopLoading();
      localStorage.setItem("journal_Number", Record.journal_Number + 1);

      //   saveRecord(setrecord, savedata).then((res) => {
      //     console.log("Resonce of saveRecord - ", res);
      //     if (res.success) {
      //       store.stopLoading();
      //       SuccessAlert("User Submitted Successfully");
      //       store.navback();
      //     } else {
      //       store.stopLoading();
      //       ErrorAlert(
      //         res?.errormessage?.response?.data?.errorMessage ||
      //           " Error Found Please contact the Admin"
      //       );
      //     }
      //   });
    }
  }

  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }

  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  // *Handle Change in the fields
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    UploadFile(attachment_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment", res.data);
      }
    });
  }

  return (
    <AccountsLayout HeaderTitle={"General Ledger"}>
      <div className="flex px-10 pt-1 bg-Alice_Blue rounded-lg">
        <div // fromDate
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.fromDate.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            From Date
            {props.fromDate.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
              (props.fromDate.mandatory && props.fromDate.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"fromDate"}
            type="date"
            value={Record.fromDate.split("T")[0]}
            disabled={props.fromDate.readonly}
            onChange={(event) => {
              handleFieldChange("fromDate", event.target.value + "T00:00:00Z");
              loadRecord(event.target.value + "T00:00:00Z", Record.ToDate);
            }}
          />
        </div>
        <div // ToDate
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.ToDate.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            To Date
            {props.ToDate.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
              (props.ToDate.mandatory && props.ToDate.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"ToDate"}
            type="date"
            value={Record.ToDate.split("T")[0]}
            disabled={props.ToDate.readonly}
            onChange={(event) => {
              handleFieldChange("ToDate", event.target.value + "T00:00:00Z");
              loadRecord(Record.fromDate, event.target.value + "T00:00:00Z");
            }}
          />
        </div>
      </div>
      <div className="flex  h-[90%] px-10 pt-2 bg-Alice_Blue rounded-lg">
        <div className="w-full p-2 ">
          <div className="h-[95%]">
            <div className="flex gap-2 p-1 font-bold">
              <span className="w-[60%]">Account Name</span>
              <span className="w-[20%] px-2 pt-1 border-l">Debit</span>
              <span className="w-[20%] px-2 pt-1 border-l">Credit</span>
            </div>
            <div className=" h-[90%] overflow-y-auto p-1">
              {Record.entries.map((item, index) => {
                if (item.amount != 0) {
                  return (
                    <>
                      <div
                        key={index}
                        className="flex gap-2 border-b border-tertiary"
                        onClick={() => {
                          let entries = Record.entries;
                          if (item.show) {
                            entries[index].show = false;
                          } else {
                            entries[index].show = true;
                          }
                          handleFieldChange("entries", entries);
                        }}
                      >
                        <span className="w-[60%]">{item.ledger_name}</span>
                        <span className="w-[20%] px-2 pt-1 border-l">
                          {item.debit}
                        </span>
                        <span className="w-[20%] px-2 pt-1 border-l">
                          {item.credit}
                        </span>
                      </div>
                      {item.show &&
                        item.subgroups.map((subitems, subindex) => {
                          if (item.amount != 0) {
                            return (
                              <div
                                key={subindex}
                                className="flex gap-2 border-b border-tertiary bg-Azureish_White"
                              >
                                <span className="w-[60%] pl-3">
                                  {subitems.ledger_name}
                                </span>
                                <span className="w-[20%] px-2 pt-1 border-l">
                                  {subitems.debit}
                                </span>
                                <span className="w-[20%] px-2 pt-1 border-l">
                                  {subitems.credit}
                                </span>
                              </div>
                            );
                          }
                        })}
                    </>
                  );
                }
              })}
            </div>
          </div>
          <div className="h-[5%] font-semibold flex gap-2 justify-end ">
            <span className="w-[20%] ">
              {"Total Debit : " + Record.totalDebit}
            </span>
            <span className="w-[20%] ">
              {"Total Credit : " + Record.totalCredit}
            </span>
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default GeneralLedger;
