import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import {
  getlocal,
  getStorage,
  setStorage,
} from "../../../functions/localstorage";
import {
  baseurl,
  ErrorAlert,
  getList,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UploadFile,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import {
  MdCancel,
  MdDataSaverOff,
  MdOutlineCancel,
  MdOutlineDataSaverOff,
  MdOutlineFileUpload,
  MdOutlineFreeCancellation,
  MdOutlinePaid,
} from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { PrimaryBTN } from "../../../Constancs/cssconst";
import {
  FaArrowRight,
  FaPenNib,
  FaRegCalendarCheck,
  FaTruckLoading,
} from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { GiFinishLine } from "react-icons/gi";
import axios from "axios";

function FormHeader({
  State = "New",
  FormName = "",
  FormNumber = "",
  Actions = [],
}) {
  return (
    <div className="flex items-center justify-between bg-secondary h-[38px] rounded-lg p-1 shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] ">
      <div className="flex gap-3 h-full items-center">
        <span className=" text-[14px] border flex items-center px-1 py-0.5 rounded-lg">
          {State}
        </span>
        <span className="text-[14px]  flex flex-col">
          <span className="text-[12px] font-bold text-Ocean_Blue">
            {FormName}
          </span>
          <span className="text-[14px] font-semibold ">{FormNumber}</span>
        </span>
      </div>
      <div className="flex gap-3 h-full items-center mr-8">
        {Actions.map((item, index) => {
          if (item.visible)
            return (
              <button
                key={index}
                className={
                  "border px-2 py-1 rounded-lg font-semibold hover:bg-tertiary " +
                  (item.type === "primary"
                    ? " bg-Ocean_Blue text-secondary "
                    : " bg-Alice_Blue ")
                }
                onClick={item.onClick}
              >
                {item.ButtonName}
              </button>
            );
        })}
      </div>
    </div>
  );
}

function FormStage({ Stages = [] }) {
  return (
    <div className="flex items-center justify-center h-[38px] gap-3">
      {Stages.map((stage, index) => (
        <div key={index} className=" flex gap-1 items-center">
          <span
            className={
              " border p-1 rounded-full text-[14px] " +
              (stage.selected
                ? " bg-Ocean_Blue text-secondary "
                : stage.completed === true
                ? " bg-[#3ec95c] text-secondary "
                : stage.completed === false
                ? " bg-[#c93e3e] text-secondary "
                : " ")
            }
          >
            {stage.completed ? <TiTick /> : stage.icon}
          </span>
          <h3 className="text-[14px] pr-3">{stage.Name}</h3>
          {index < Stages.length - 1 ? <FaArrowRight /> : ""}
        </div>
      ))}
    </div>
  );
}

const PayablesForm = () => {
  const { option, expenseId } = useParams();
  const navigate = useNavigate();
  const getrecord = `/users/users/${expenseId}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();
  let storage = getStorage();

  // *initial values
  const init = {
    expenseNumber: "PAY/24-25/0010",
    customerName: "",
    bankName: "",
    deliveryAddress: "",
    date: "",
    dueDate: "",
    stage: "draft",

    expenseType: "byCompany",

    Transaction: [
      {
        Group: "",
        salesAccount: "",
        Quantity: "",
        Rate: "",
        Amount: "",
      },
    ],

    notes: "Notes: ",
    terms: "Terms & Conditions: ",
    projectName: "",
    subTotal: 0,

    discountPercentage: 0,
    discountType: "%",
    discountValue: 0,

    Total: 0,
    attachment: [],
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,

    stage: {},
    Stages: {
      Stages_list: [
        {
          Name: "Draft",
          icon: <FaPenNib />,
          selected: true,
        },
        {
          Name: "Awaiting Approval",
          icon: <FaRegCalendarCheck />,
          selected: false,
        },
        {
          Name: "Awaiting Payment",
          icon: <MdOutlinePaid />,
          selected: false,
        },
      ],
    },
    salesOrder: {
      salesOrder_list: [
        { value: "1", label: "Sales" },
        { value: "2", label: "TradeMark Sales" },
        { value: "3", label: "Agreements Sales" },
      ],
    },

    deliveryAddress: {
      deliveryAddress_list: [{ value: "", label: "Please Choose" }],
    },
    projectName: {
      projectName_list: [{ value: "", label: "Please Choose" }],
    },
    customerName: {
      customerName_list: [{ value: "", label: "Please Choose" }],
    },
    bankName: {
      bankName_list: [{ value: "", label: "Please Choose" }],
    },
    DeliveryAdderss: {
      DeliveryAdderss_list: [{ value: "", label: "Please Choose" }],
    },
    date: {},
    dueDate: {},

    expenseType: {},

    Transaction: {
      particulars_list: [
        {
          value: 1,
          label: "[COMM] Communication",
        },
        {
          value: 2,
          label: "[EXP_GEN] Expenses",
        },
        {
          value: 3,
          label: "[FOOD] Meals",
        },
        {
          value: 4,
          label: "[GIFT] Gifts",
        },
        {
          value: 5,
          label: "[MIL] Mileage",
        },
      ],
    },

    subTotal: {},

    discountPercentage: {},
    discountType: {
      discountType_list: [
        { value: "%", label: "%" },
        { value: "₹", label: "₹" },
      ],
    },
    discountValue: {},

    Total: {},

    notes: {},
    terms: {},
    attachment: {},
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  // *and change on dependencies of option and id changes
  useEffect(() => {
    const localprops = getlocal("properties")?.companies || {};
    getList({
      listUrl: "/project/getprojectlist",
      field: "projectName",
      sort: "asc",
      count: 100,
      store: store,
    }).then((res) => {
      console.log("project/getprojectlist");
      let particulars_list = [{ value: "", label: "Please Choose" }];
      res.data.list.map((item, index) => {
        particulars_list.push({ label: item.projectName, value: item.id });
      });
      console.log("project/getprojectlist", particulars_list);
      HandlePropsChange("projectName", {
        projectName_list: particulars_list,
      });
    });
    HandleMultiPropsChange(localprops);
    let customerName_list = [{ value: "", label: "Please Choose" }];
    storage.ledger.map((item, index) => {
      if (item.group === "2")
        customerName_list.push({ label: item.ledgerName, value: index });
    });
    HandlePropsChange("customerName", {
      customerName_list,
    });

    // console.log("Params",param);
    if (expenseId !== undefined) {
      handleMultiFieldChange(storage.Expense[expenseId]);
      let Stage = props.Stages.Stages_list;

      Stage[0].selected = false;
      Stage[0].completed = true;
      Stage[1].selected = false;
      Stage[1].completed = true;
      Stage[1].hidden = true;

      HandlePropsChange("Stages", { Stages_list: Stage });
      // store.setheader(option.toUpperCase() + " Company");
      // HandlePropsChange("company_status", { hidden: false });
      // if (option === "view") {
      //   HandleMultiPropsChange({
      //     company_logo: { readonly: true },
      //     company_name: { readonly: true },
      //     company_address_line1: { readonly: true },
      //     company_address_line2: { readonly: true },
      //     company_address_city: { readonly: true },
      //     company_address_pincode: { readonly: true },
      //     company_address_state: { readonly: true },
      //     company_phone_alternative: { readonly: true },
      //     company_website_url: { readonly: true },
      //     company_phone: { readonly: true },
      //     company_email: { readonly: true },
      //     company_status: { readonly: true },
      //   });
      // } else {
      //   HandleMultiPropsChange({
      //     company_logo: { readonly: false },
      //     company_name: { readonly: false },
      //     company_address_line1: { readonly: false },
      //     company_address_line2: { readonly: false },
      //     company_address_city: { readonly: false },
      //     company_address_pincode: { readonly: false },
      //     company_address_state: { readonly: false },
      //     company_phone_alternative: { readonly: false },
      //     company_website_url: { readonly: false },
      //     company_phone: { readonly: false },
      //     company_email: { readonly: false },
      //     company_status: { readonly: false },
      //   });
      // }
      // GetRecords(getrecord).then((res) => {
      //   console.log("Companies Data - ", res, res.record);
      //   if (res.success) {
      //     handleMultiFieldChange(res.record);
      //   } else if (res.code === 401) {
      //     store.logout();
      //     store.navTo("/login");
      //   }
      // });
    } else {
      store.setheader("CREATE NEW Company");
      // props for the Mandatory field
      // HandleMultiPropsChange({
      //   salesNumber: { readonly: true },
      //   salesOrder: { readonly: true },
      //   date: { readonly: true },
      //   Transaction: { readonly: true },
      //   subTotal: { readonly: true },
      //   discountPercentage: { readonly: true },
      //   discountType: { readonly: true },
      //   discountValue: { readonly: true },
      //   GSTType: { readonly: true },
      //   taxing: { readonly: true },
      //   taxValue: { readonly: true },
      //   Total: { readonly: true },
      //   notes: { readonly: true },
      // });
    }
  }, [option, expenseId]);

  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record, props]);

  // *create Or update the Record
  function update() {}
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      store.stopLoading();
      SuccessAlert("Expense Submitted Successfully");
      BtnSubmit();
      let newExpense = storage.Expense || [];
      newExpense.push(savedata);
      console.log("newExpense", newExpense);
      setStorage({
        ...storage,
        Expense: newExpense,
      });
    }
  }
  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }
  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  // *Handle Change in the fields
  function HandleCustomer(value) {
    const customer = GetRecordFromList(
      props.customerName.customerName_list,
      value
    );
    if (customer != undefined) {
      let Transaction = Record.Transaction;
      Transaction[0].salesAccount = customer.salesAccount;
      handleFieldChange("Transaction", Transaction);
      handleFieldChange("DeliveryAdderss", 1);
    }
  }
  function HandleForInternal() {
    if (Record.expenseType === "forInternal") {
      handleMultiFieldChange({ expenseType: "byCompany" });
      HandleMultiPropsChange({
        customerName: { hidden: false },
        deliveryAddress: { hidden: false },
        projectName: { hidden: false },
        bankName: { hidden: true },
      });
    } else {
      handleMultiFieldChange({ expenseType: "forInternal" });
      HandleMultiPropsChange({
        customerName: { hidden: true },
        deliveryAddress: { hidden: true },
        projectName: { hidden: true },
        bankName: { hidden: false },
      });
    }
  }
  function HandleByCompany() {
    handleMultiFieldChange({ expenseType: "byCompany" });
    HandleMultiPropsChange({
      customerName: { hidden: false },
      deliveryAddress: { hidden: false },
      projectName: { hidden: false },
      bankName: { hidden: true },
    });
  }
  function HandleByEmployee() {
    handleMultiFieldChange({ expenseType: "byEmployee" });
    HandleMultiPropsChange({
      customerName: { hidden: false },
      deliveryAddress: { hidden: false },
      projectName: { hidden: false },
      bankName: { hidden: true },
    });
  }
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    let attachment = Record.attachment || [];
    UploadFile(attachment_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment", [...attachment, res.data]);
      }
    });
  }

  // *Handle Button
  function BtnSubmit() {
    let Stage = props.Stages.Stages_list;

    Stage[0].selected = false;
    Stage[0].completed = true;
    Stage[0].Name = "Submitted";
    Stage[1].selected = true;

    HandlePropsChange("Stages", { Stages_list: Stage });
    handleFieldChange("stage", "awaitingApproval");
  }
  function BtnApproval(stage) {
    let Stages = props.Stages.Stages_list;
    Stages[0].selected = false;
    Stages[0].completed = true;
    Stages[0].Name = "Submitted";
    if (stage === "Approve") {
      Stages[1].selected = false;
      Stages[1].completed = true;
      Stages[1].Name = "Approved";
      Stages[2].selected = true;
      Stages[2].Name = "Awaiting Payment";
      let savedata = {
        debitAccountId: 12,
        creditAccountId: 9,
        amount: Record.Total,
        company_id: 1,
        proj_id: 0,
        cust_id: 1,
        description: "Auto Created by Expense Creation",
        createdBy: store.getuser().id + "",
      };
      // saveRecord("/api/v2/accounting/journalentry", savedata).then((res) => {
      //   console.log("Resonce of saveRecord - ", res);
      //   if (res.success) {
      //     store.stopLoading();
      //     SuccessAlert("Approved Successfully");
      //     axios.put(
      //       baseurl + "/api/v2/accounting/journalstatusupdate?id=" + res.Id.id,
      //       {
      //         headers: { "Content-Type": "application/json" },
      //       }
      //     );
      //     store.navback();
      //   } else {
      //     store.stopLoading();
      //     ErrorAlert(
      //       res?.errormessage?.response?.data?.errorMessage ||
      //         " Error Found Please contact the Admin"
      //     );
      //   }
      // });
      handleFieldChange("stage", "approved");
    } else {
      Stages[1].selected = false;
      Stages[1].completed = false;
      Stages[1].Name = "Rejected";
      Stages[1].icon = <MdCancel />;
      Stages = Stages.filter((item, index) => index != 2);
      handleFieldChange("stage", "rejected");
    }
    HandlePropsChange("Stages", { Stages_list: Stages });
  }
  function BtnPaid() {
    let Stages = props.Stages.Stages_list;
    Stages[0].selected = false;
    Stages[0].completed = true;
    Stages[0].Name = "Submitted";
    Stages[1].selected = false;
    Stages[1].completed = true;
    Stages[1].Name = "Approved";
    Stages[2].selected = false;
    Stages[2].completed = true;
    Stages[2].Name = "Paid";
    HandlePropsChange("Stages", { Stages_list: Stages });
    handleFieldChange("stage", "paid");
  }

  // calculate total value
  function TotalCalculation(Transaction = Record.Transaction) {
    let subTotal = 0;
    let discountValue = 0;
    let Total = 0;
    let discountPercentage = Number(Record.discountPercentage);

    Transaction.map((item) => {
      if (item.Amount !== "" && item.Amount !== undefined) {
        subTotal += Number(item.Amount);
      }
    });

    discountValue = (discountPercentage / 100) * subTotal;
    Total = subTotal - discountValue;
    handleFieldChange("subTotal", subTotal.toFixed(2));
    handleFieldChange("discountValue", discountValue.toFixed(2));
    handleFieldChange("Total", Total.toFixed(2));
  }

  // get specific record in list
  function GetRecordFromList(List = [], value) {
    List = List.filter((item) => item.value == value);
    if (List.length > 0) {
      return List[0];
    } else {
      return undefined;
    }
  }

  return (
    <AccountsLayout HeaderTitle={"Payable"}>
      <div className="flex flex-col h-full text-[12px]">
        <FormHeader
          State="Draft"
          FormName="Payable"
          FormNumber={Record.expenseNumber}
          Actions={[
            {
              ButtonName: "Submitted",
              type: "primary",
              onClick: Submit,
              visible: Record.stage === "draft",
            },
            {
              ButtonName: "Approve",
              type: "primary",
              onClick: () => {
                BtnApproval("Approve");
              },
              visible: Record.stage === "awaitingApproval",
            },
            {
              ButtonName: "Reject",
              onClick: () => {
                BtnApproval("Reject");
              },
              visible: Record.stage === "awaitingApproval",
            },
            {
              ButtonName: "Paid",
              type: "primary",
              onClick: () => {
                BtnPaid();
              },
              visible: Record.stage === "approved",
            },
            {
              ButtonName: "Back",
              onClick: () => {
                navigate(-1);
              },
              visible: true,
            },
          ]}
        />
        <FormStage
          Stages={props.Stages.Stages_list}
          currentStage={Record.stage}
        />
        <div className="flex bg-secondary flex-col items-center flex-grow rounded-lg shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] overflow-auto">
          <div className="grid grid-cols-2 gap-2 w-[80%] mt-3">
            <div // bankName
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.bankName.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Paid Through
                {props.bankName.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"bankName"}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.bankName.mandatory && props.bankName.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("bankName", event.target.value);
                  HandleCustomer(event.target.value);
                  TotalCalculation();
                }}
                value={Record.bankName}
                disabled={props.bankName.readonly}
                aria-placeholder="please select sam"
              >
                {props.bankName.bankName_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            <div // date
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.date.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Date
                {props.date.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
                  (props.date.mandatory && props.date.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"date"}
                type="date"
                value={Record.date.split("T")[0]}
                disabled={props.date.readonly}
                onChange={(event) => {
                  handleFieldChange("date", event.target.value + "T00:00:00Z");
                }}
              />
            </div>
          </div>
          <div // Transaction
            className={
              " text-[14px] w-[80%] px-1 mt-2 flex-col items-center gap-1 flex py-2 relative "
            }
          >
            <div className="flex justify-around w-full gap-2 border-y border-Old_Silver border-opacity-35">
              <div className="text-[14px] w-full px-2 py-1">Expenses</div>
              <div className="text-[14px] w-full px-2 py-1">Note</div>
              <div className="text-[14px] w-full px-2 py-1">Amount</div>
              <span className=" mr-4 rounded-lg  cursor-pointer"></span>
            </div>
            {Record.Transaction.map((item, index) => (
              <div
                className="flex justify-around w-full gap-2 border-b border-Old_Silver border-opacity-35"
                key={index}
              >
                <select // Transaction.expenses
                  id={"Transaction"}
                  className={
                    " w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-35 outline-none " +
                    (props.Transaction.mandatory && props.Transaction.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  onChange={(event) => {
                    let Transaction = Record.Transaction;
                    Transaction[index].expenses = event.target.value;
                    handleFieldChange("Transaction", Transaction);
                    // HandleParticulars(event.target.value, index);
                  }}
                  value={item.expenses}
                  disabled={props.Transaction.readonly}
                >
                  <option value={""}>Please Choose</option>
                  {props.Transaction.particulars_list.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                <input // Transaction.note
                  className={
                    "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                    (props.Transaction.mandatory && props.Transaction.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"Transaction"}
                  type="text"
                  value={item.note}
                  disabled={props.Transaction.readonly}
                  onChange={(event) => {
                    let Transaction = Record.Transaction;
                    Transaction[index].note = event.target.value;
                    handleFieldChange("Transaction", Transaction);
                    TotalCalculation();
                  }}
                />
                <input // Transaction.Amount
                  className={
                    "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                    (props.Transaction.mandatory && props.Transaction.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"Transaction"}
                  type="number"
                  value={item.Amount}
                  disabled={props.Transaction.readonly}
                  onChange={(event) => {
                    let Transaction = Record.Transaction;
                    Transaction[index].Amount = event.target.value;
                    handleFieldChange("Transaction", Transaction);
                    TotalCalculation();
                  }}
                />
                <span
                  onClick={() => {
                    let Transaction = Record.Transaction;
                    Transaction = Transaction.filter(
                      (Transactionitem, Transactionindex) =>
                        Transactionindex != index
                    );
                    handleFieldChange("Transaction", Transaction);
                    TotalCalculation(Transaction);
                  }}
                  className="border h-fit w-fit p-1 rounded-lg hover:bg-tertiary cursor-pointer"
                >
                  X
                </span>
              </div>
            ))}
            <div className="flex px-2 pb-2 w-full gap-2 border-b border-Old_Silver border-opacity-35">
              <button
                className={"text-Ocean_Blue hover:drop-shadow-2xl"}
                onClick={() => {
                  let Transaction = Record.Transaction;
                  Transaction.push({
                    expenses: "",
                    note: "",
                    Amount: "",
                  });
                  console.log("let Transaction", Transaction);

                  handleFieldChange("Transaction", Transaction);
                }}
              >
                Add New Line
              </button>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2 w-[80%] mt-1">
            <div // notes
              className={
                "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1  " +
                (props.notes.hidden ? " hidden " : " flex ")
              }
            >
              <textarea
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                  (props.notes.mandatory && props.notes.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"notes"}
                value={Record.notes}
                disabled={props.notes.readonly}
                onChange={(event) => {
                  if (event.target.value.length > 7) {
                    handleFieldChange("notes", event.target.value);
                  } else {
                    handleFieldChange("notes", "Notes: ");
                  }
                }}
              />
            </div>
            <div // Total
              className={"flex items-center justify-between px-4 "}
            >
              <div className="text-[14px] text-Old_Silver flex-grow ">
                {"Total (₹)"}
              </div>
              <div className=" w-[300px] text-[16px] font-semibold px-2 py-1 border-Old_Silver ">
                {Record.Total}
              </div>
            </div>
            <div // terms
              className={
                "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1  " +
                (props.terms.hidden ? " hidden " : " flex ")
              }
            >
              <textarea
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                  (props.terms.mandatory && props.terms.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"terms"}
                value={Record.terms}
                disabled={props.terms.readonly}
                onChange={(event) => {
                  if (event.target.value.length > 20) {
                    handleFieldChange("terms", event.target.value);
                  } else {
                    handleFieldChange("terms", "Terms & Conditions: ");
                  }
                }}
              />
            </div>
            <div // attachments
              className={"flex flex-col px-4  "}
            >
              <h3 className="text-[14px]">Attachments</h3>
              <div // attachment
                className={
                  "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                  (props.attachment.hidden ? " hidden " : " flex ")
                }
              >
                <button
                  className="border px-2 py-1 rounded-lg flex gap-1 items-center"
                  onClick={() => {
                    document.getElementById("FileInput").click();
                  }}
                >
                  <MdOutlineFileUpload className="text-[#20b25b] text-[18px] font-bold" />
                  Upload File
                </button>
                {Record.attachment.map((attached) => (
                  <a
                    className="cursor-pointer hover:text-[#3f4fff] hover:bg-[#2a3cff] hover:bg-opacity-15 px-2 py-1 rounded-lg flex items-center gap-1 group transition-all duration-100"
                    href={baseurl + "/files/preview?key=" + attached}
                    target="_blank"
                  >
                    {attached.replaceAll("Journal_", "")}
                    <FaRegEye className="group-hover:block hidden transition-all duration-75" />
                  </a>
                ))}
                <input
                  type="file"
                  onChange={HandleAttachement}
                  id="FileInput"
                  className="hidden"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default PayablesForm;
