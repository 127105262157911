import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { getlocal } from "../../functions/localstorage";
import Login from "../../pages/Login";
import { URLPRIFIX } from "../../functions/api";
import Dashboard from "../../pages/Dashboard";
import { useStore } from "../StateProvider";
import UserList from "../../pages/users/UserList";
import UserForm from "../../pages/users/UserForm";
import ContactsList from "../../pages/Contacts/ContactsList";
import ContactForm from "../../pages/Contacts/ContactForm";
import NonLitigation from "../../pages/NonLitigation";
import FormsList from "../../pages/ClientForms/FormsList";
import FormsForm from "../../pages/ClientForms/FormsForm";
import CategoryList from "../../pages/Category/CategoryList";
import CategoryForm from "../../pages/Category/CategoryForm";
import FilmForm from "../../pages/Film/FilmForm";
import ProjectList from "../../pages/Project/ProjectList";
import ProjectForm from "../../pages/Project/ProjectForm";
import DocumentsList from "../../pages/Documents/DocumentsList";
import DocumentsForm from "../../pages/Documents/DocumentsForm";
import TasksList from "../../pages/Tasks/TasksList";
import TasksForm from "../../pages/Tasks/TasksForm";
import Editor from "../../pages/Editor";
import FilmFormCreate from "../../pages/ClientForms/FilmFormCreate";
import Layout from "./Layout";
import UnAuthorized401 from "../../pages/UnAuthorized401";
import FilmList from "../../pages/Film/FilmList";
import CompanyList from "../../pages/Companies/CompanyList";
import CompanyForm from "../../pages/Companies/CompanyForm";
import CompanyCardList from "../../pages/Companies/CompanyCardList";
import JournalsForm from "../../pages/Accounts/Journals/journalsForm";
import JournalsList from "../../pages/Accounts/Journals/JournalsList";
import GroupList from "../../pages/Accounts/Group/GroupList";
import LedgerList from "../../pages/Accounts/Ledger/LedgerList";
import GroupForm from "../../pages/Accounts/Group/GroupForm";
import LedgerForm from "../../pages/Accounts/Ledger/LedgerForm";
import AccountsDashboard from "../../pages/Accounts/AccountsDashboard";
import SalesForm from "../../pages/Accounts/Sales/SalesForm";
import SalesList from "../../pages/Accounts/Sales/SalesList";
import InvoiceForm from "../../pages/Accounts/Invoice/InvoiceForm";
import InvoiceList from "../../pages/Accounts/Invoice/InvoiceList";
import ReceiptForm from "../../pages/Accounts/Receipt/ReceiptForm";
import ReceiptList from "../../pages/Accounts/Receipt/ReceiptList";
import BalanceSheet from "../../pages/Accounts/Report/BalanceSheet";
import TrailBalance from "../../pages/Accounts/Report/TrailBalance";
import ExpenseForm from "../../pages/Accounts/Expense/ExpenseForm";
import ExpenseList from "../../pages/Accounts/Expense/ExpenseList";
import ProfitLoss from "../../pages/Accounts/Report/ProfitLoss";
import CustomerForm from "../../pages/Accounts/Customer/CustomerForm";
import CustomerList from "../../pages/Accounts/Customer/CustomerList";
import PayablesForm from "../../pages/Accounts/Payables/PayablesForm";
import PayablesList from "../../pages/Accounts/Payables/PayablesList";
import GeneralLedger from "../../pages/Accounts/Report/GeneralLedger";

const PageRouter = () => {
  const store = useStore();
  const props = getlocal("properties");

  console.log("PageRouter Props", props);

  let url = window.location.pathname;
  useEffect(() => {
    let login = getlocal("system").login;
    if (!url.includes("/demo/forms")) {
      if (!login) {
        store.navTo("/login");
      } else {
        if (url === "/login" || url === "/") {
          store.navTo("/" + URLPRIFIX + "/dashboard");
        }
      }
    }
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Navigate to={"/login"} />} />
      <Route path="/login" element={<Login />} />
      <Route
        path={"/demo/dashboard"}
        element={
          props?.dashboard?.on_off ? (
            <Layout>
              <Dashboard />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/nonlitigation/:category"}
        element={
          props?.nonlitigation?.on_off ? (
            <Layout>
              <NonLitigation />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/users/list"}
        element={
          props?.users?.on_off ? (
            <Layout>
              <UserList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/users/create"}
        element={
          props?.users?.on_off && props?.users?.add ? (
            <Layout>
              <UserForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/users/:option/:id"}
        element={
          props?.users?.on_off ? (
            <Layout>
              <UserForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/contacts/list"}
        element={
          props?.contacts?.on_off ? (
            <Layout>
              <ContactsList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/contacts/create"}
        element={
          props?.contacts?.on_off && props?.contacts?.add ? (
            <Layout>
              <ContactForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/contacts/:option/:id"}
        element={
          props?.contacts?.on_off ? (
            <Layout>
              <ContactForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/categories/list"}
        element={
          props?.categories?.on_off ? (
            <Layout>
              <CategoryList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/categories/create"}
        element={
          props?.categories?.on_off && props?.categories?.add ? (
            <Layout>
              <CategoryForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/categories/:option/:id"}
        element={
          props?.categories?.on_off ? (
            <Layout>
              <CategoryForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/filmmaster/create"}
        element={
          props?.film?.on_off && props?.film?.add ? (
            <Layout>
              <FilmForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"demo/filmmaster/list"}
        element={
          props?.film?.on_off ? (
            <Layout>
              <FilmList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/filmmaster/:option/:id"}
        element={
          props?.film?.on_off ? (
            <Layout>
              <FilmForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/projects/list"}
        element={
          props?.projects?.on_off ? (
            <Layout>
              <ProjectList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/projects/create"}
        element={
          props?.projects?.on_off && props?.projects?.add ? (
            <Layout>
              <ProjectForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/projects/:option/:id"}
        element={
          props?.projects?.on_off ? (
            <Layout>
              <ProjectForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/documents/list"}
        element={
          props?.documents?.on_off ? (
            <Layout>
              <DocumentsList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/documents/create"}
        element={
          props?.documents?.on_off && props?.documents?.add ? (
            <Layout>
              <DocumentsForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/documents/:option/:id"}
        element={
          props?.documents?.on_off ? (
            <Layout>
              <DocumentsForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/tasks/list"}
        element={
          props?.tasks?.on_off ? (
            <Layout>
              <TasksList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/tasks/create"}
        element={
          props?.tasks?.on_off && props?.tasks?.add ? (
            <Layout>
              <TasksForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/tasks/:option/:id"}
        element={
          props?.tasks?.on_off ? (
            <Layout>
              <TasksForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/companies/list"}
        element={
          props?.tasks?.on_off ? (
            <Layout>
              <TasksList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/accounts"}
        element={
          props?.tasks?.on_off ? (
            <Layout>
              <CompanyCardList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/companies/create"}
        element={
          props?.tasks?.on_off && props?.tasks?.add ? (
            <Layout>
              <CompanyForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/companies/:option/:id"}
        element={
          props?.tasks?.on_off ? (
            <Layout>
              <CompanyForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/forms/list"}
        element={
          props?.forms?.on_off ? (
            <Layout>
              <FormsList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/forms/:option/:id"}
        element={
          props?.forms?.on_off ? (
            <Layout>
              <FormsForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/forms/:clientId/:categoryid/:filmid/:filmname/:formid"}
        element={<FilmFormCreate />}
      />
      <Route path={"/demo/editor/:id"} element={<Editor />} />

      {/* Accounts screen routing */}
      {/* Dashboard Navigation */}
      <Route
        path={"/demo/:companyId/accounts/Dashboard"}
        element={<AccountsDashboard />}
      />

      {/* Journal Navigation */}
      <Route
        path={"/demo/:companyId/accounts/journal/:option"}
        element={<JournalsForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/journal/:option/:journalId"}
        element={<JournalsForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/journal"}
        element={<JournalsList />}
      />

      {/* sales Navigation */}
      <Route
        path={"/demo/:companyId/accounts/sales/:option"}
        element={<SalesForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/sales/:option/:salesId"}
        element={<SalesForm />}
      />
      <Route path={"/demo/:companyId/accounts/sales"} element={<SalesList />} />

      {/* invoice Navigation */}
      <Route
        path={"/demo/:companyId/accounts/invoice/:option"}
        element={<InvoiceForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/invoice/:option/:invoiceId"}
        element={<InvoiceForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/invoice"}
        element={<InvoiceList />}
      />

      {/* expense Navigation */}
      <Route
        path={"/demo/:companyId/accounts/expense/:option"}
        element={<ExpenseForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/expense/:option/:expenseId"}
        element={<ExpenseForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/expense"}
        element={<ExpenseList />}
      />

      {/* payable Navigation */}
      <Route
        path={"/demo/:companyId/accounts/payable/:option"}
        element={<PayablesForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/payable/:option/:payableId"}
        element={<PayablesForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/payable"}
        element={<PayablesList />}
      />

      {/* receipt Navigation */}
      <Route
        path={"/demo/:companyId/accounts/receipt/:option"}
        element={<ReceiptForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/receipt/:option/:receiptId"}
        element={<ReceiptForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/receipt"}
        element={<ReceiptList />}
      />

      {/* purchase Navigation */}
      <Route
        path={"/demo/:companyId/accounts/purchase/:option"}
        element={<JournalsForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/purchase/:option/:purchaseId"}
        element={<JournalsForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/purchase"}
        element={<JournalsList />}
      />

      {/* payment Navigation */}
      <Route
        path={"/demo/:companyId/accounts/payment/:option"}
        element={<JournalsForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/payment/:option/:paymentId"}
        element={<JournalsForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/payment"}
        element={<JournalsList />}
      />

      {/* Group Navigation */}
      <Route
        path={"/demo/:companyId/accounts/group/:option"}
        element={<GroupForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/group/:option/:groupId"}
        element={<GroupForm />}
      />
      <Route path={"/demo/:companyId/accounts/group"} element={<GroupList />} />

      {/* Ledger Navigation */}
      <Route
        path={"/demo/:companyId/accounts/ledger/:option"}
        element={<LedgerForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/ledger/:option/:ledgerId"}
        element={<LedgerForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/ledger"}
        element={<LedgerList />}
      />

      {/* customer Navigation */}
      <Route
        path={"/demo/:companyId/accounts/customer/:option"}
        element={<CustomerForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/customer/:option/:customerId"}
        element={<CustomerForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/customer"}
        element={<CustomerList />}
      />

      {/* Bank Navigation */}
      <Route
        path={"/demo/:companyId/accounts/bank/:option"}
        element={<LedgerForm />}
      />
      <Route
        path={"/demo/:companyId/accounts/bank/:option/:bankId"}
        element={<LedgerForm />}
      />
      <Route path={"/demo/:companyId/accounts/bank"} element={<LedgerList />} />

      {/* Reports Navigation */}
      <Route
        path={"/demo/:companyId/accounts/trailbalance"}
        element={<TrailBalance />}
      />
      <Route
        path={"/demo/:companyId/accounts/balancesheet"}
        element={<BalanceSheet />}
      />
      <Route
        path={"/demo/:companyId/accounts/profitloss"}
        element={<ProfitLoss />}
      />
      <Route
        path={"/demo/:companyId/accounts/generallegder"}
        element={<GeneralLedger />}
      />
    </Routes>
  );
};

export default PageRouter;
