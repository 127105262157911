import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import {
  getlocal,
  getStorage,
  setStorage,
} from "../../../functions/localstorage";
import {
  baseurl,
  ErrorAlert,
  getList,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UploadFile,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { IoIosCloseCircleOutline } from "react-icons/io";

import { PrimaryBTN } from "../../../Constancs/cssconst";
import { FaArrowRight, FaPenNib, FaTruckLoading } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import {
  URL_Account_Charts_List,
  URL_Account_Client_List,
  URL_Account_Customer_List,
  URL_Account_Invoice_By_ID,
} from "../../../Constancs/apiconst";
import { GiFinishLine } from "react-icons/gi";

function FormHeader({
  State = "New",
  FormName = "",
  FormNumber = "",
  Actions = [],
}) {
  return (
    <div className="flex items-center justify-between bg-secondary h-[38px] rounded-lg p-1 shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] ">
      <div className="flex gap-3 h-full items-center">
        <span className=" text-[14px] border flex items-center px-1 py-0.5 rounded-lg">
          {State}
        </span>
        <span className="text-[14px]  flex flex-col">
          <span className="text-[12px] font-bold text-Ocean_Blue">
            {FormName}
          </span>
          <span className="text-[14px] font-semibold ">{FormNumber}</span>
        </span>
      </div>
      <div className="flex gap-3 h-full items-center mr-8">
        {Actions.map((item, index) => {
          if (!item.hidden)
            return (
              <button
                key={index}
                className={
                  "border px-2 py-1 rounded-lg font-semibold hover:bg-tertiary " +
                  (item.type === "primary"
                    ? " bg-Ocean_Blue text-secondary "
                    : " bg-Alice_Blue ")
                }
                onClick={item.onClick}
              >
                {item.ButtonName}
              </button>
            );
        })}
      </div>
    </div>
  );
}

function FormStage({ Stages = [] }) {
  return (
    <div className="flex items-center justify-center h-[38px] gap-3">
      {Stages.map((stage, index) => (
        <div key={index} className=" flex gap-1 items-center">
          <span
            className={
              " border p-1 rounded-full text-[14px] " +
              (stage.selected
                ? " bg-Ocean_Blue text-secondary "
                : stage.completed
                ? " bg-[#3ec95c] text-secondary "
                : " ")
            }
          >
            {stage.completed ? <TiTick /> : stage.icon}
          </span>
          <h3 className="text-[14px] pr-3">{stage.Name}</h3>
          {index < Stages.length - 1 ? <FaArrowRight /> : ""}
        </div>
      ))}
    </div>
  );
}

const InvoiceForm = () => {
  const { companyId, option, invoiceId } = useParams();
  const navigate = useNavigate();
  const getrecord = `/users/users/${invoiceId}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();
  let storage = getStorage();

  // *initial values
  const init = {
    invoice_no: "",
    customer_id: 0,
    invoice_date: "",
    delivery_address: "",
    invoice_sub_total: 0,
    discount_type: 0,
    discount_value: 0,
    invoice_discount: 0,
    invoice_total: 0,
    invoice_due_date: "",
    invoice_customer_notes: "Notes: ",
    invoice_terms_and_conditions: "Terms & Conditions: ",
    attachment: [],
    items: [
      {
        project_id: 0,
        charts_acc_id: 0,
        description: "",
        amount: 0,
      },
    ],
    expense_id: [
      {
        amount: 250,
        description: "Lunch With Client",
        name: "Food [MEAL]",
      },
    ],
  };

  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,

    invoice_no: {},
    customer_id: { customer_id_list: [{ value: "", label: "Please Choose" }] },
    invoice_date: {},
    delivery_address: {
      delivery_address_list: [{ value: "", label: "Please Choose" }],
    },
    invoice_sub_total: {},

    discount_value: {},
    discount_type: {
      discount_type_list: [
        { value: "6", label: "%" },
        { value: "5", label: "₹" },
      ],
    },
    invoice_discount: {},
    invoice_total: {},
    invoice_due_date: {},
    invoice_customer_notes: {},
    invoice_terms_and_conditions: {},
    attachment: {},
    items: {
      project_list: [
        { value: "", label: "Please Choose" },
        { value: "1", label: "Project SANA" },
      ],
      salesOrder_list: [{ value: "", label: "Please Choose" }],
    },
    expense_id: {
      expense_id_list: [
        { value: "", label: "Please Choose" },
        { value: "1", label: "Food [MEAL]" },
      ],
    },
    Stages: {
      Stages_list: [
        { selected: true, Name: "Draft", icon: <FaPenNib /> },
        {
          Name: "Posted",
          icon: <FaTruckLoading />,
          selected: false,
          completed: false,
        },
        {
          Name: "Completed",
          icon: <GiFinishLine />,
          selected: false,
          completed: false,
        },
      ],
    },
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  // *and change on dependencies of option and id changes
  useEffect(() => {
    GetRecords(URL_Account_Customer_List).then((res) => {
      if (res.success) {
        res = res.record.list;
        console.log("URL_Account_Customer_List", res);
        let customer_id_list = initProps.customer_id.customer_id_list;
        res.map((customer) =>
          customer_id_list.push({
            ...customer,
            label: customer.clientname,
            value: customer.id,
          })
        );
        HandlePropsChange("customer_id", {
          ...props.customer_id,
          customer_id_list,
        });
      }
    });
    GetRecords(URL_Account_Charts_List).then((res) => {
      if (res.success) {
        res = res?.record?.list || [];
        res = res.filter((item) => item.parent_id === 18);
        console.log("URL_Account_Charts_List", res);
        let salesOrder_list = initProps.items.salesOrder_list;

        res.map((sales) =>
          salesOrder_list.push({
            ...sales,
            label: sales.ledger_name,
            value: sales.id,
          })
        );
        HandlePropsChange("items", {
          ...props.items,
          salesOrder_list,
        });
      }
    });

    if (invoiceId !== undefined) {
      GetRecords(URL_Account_Invoice_By_ID + invoiceId).then((res) => {
        if (res.success) {
          res = res.record;
          console.log("URL_Account_Invoice_By_ID", res);
          let invoice = res.invoice;
          HandleStates(invoice.invoice_status);
          handleMultiFieldChange({
            invoice_no: invoice.invoice_no,
            customer_id: invoice.customer_ledger_id,
            invoice_date: invoice.invoice_date,
            delivery_address: invoice.delivery_address,
            invoice_sub_total: invoice.invoice_sub_total,
            invoice_discount_Value: invoice.invoice_discount_Value,
            discount_type: invoice.discount_type,
            invoice_discount: invoice.invoice_discount,
            invoice_total: invoice.invoice_total,
            invoice_status: invoice.invoice_status,
            invoice_due_date: invoice.invoice_due_date,
            invoice_customer_notes: "Notes: " + invoice.invoice_customer_notes,
            invoice_terms_and_conditions:
              "Terms & Conditions: " + invoice.invoice_terms_and_conditions,
            items: invoice.items,
            expense_id: [],
          });
        }
      });
    } else {
      store.setheader("CREATE NEW Company");
    }
  }, [option, invoiceId]);

  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record, props]);

  // *create Or update the Record
  function update() {}
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      // saveRecord("/api/v2/accounting/invoices", savedata).then((res) => {
      //   console.log("Resonce of saveRecord - ", res);
      //   if (res.success) {
      //     store.stopLoading();
      //     SuccessAlert("Invoice Submitted Successfully");
      //     store.navback();
      //   } else {
      //     store.stopLoading();
      //     ErrorAlert(
      //       res?.errormessage?.response?.data?.errorMessage ||
      //         " Error Found Please contact the Admin"
      //     );
      //   }
      // });
    }
  }
  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }
  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  // *Handle Change in the fields
  function HandleCustomer(value) {
    const customer = GetRecordFromList(
      props.customer_id.customer_id_list,
      value
    );
    if (customer != undefined) {
      let items = Record.items;
      items[0].salesAccount = customer.salesAccount;
      handleFieldChange("items", items);
      handleFieldChange("delivery_address", 1);
    }
  }
  function Handlecharts_acc_id(value, index) {
    let items = Record.items;

    items[index].charts_acc_id = value;

    handleFieldChange("items", items);
    TotalCalculation();
  }
  function Handleproject_id(value, index) {
    let items = Record.items;
    const project_list = GetRecordFromList(props.items.project_list, value);
    items[index].project_id = value;
    items[index].charts_acc_id = project_list.charts_acc_id;
    items[index].description = project_list.description;
    items[index].amount = project_list.amount;

    handleFieldChange("items", items);
    TotalCalculation();
  }
  function HandleDiscountValue(discount_value) {
    let invoice_discount = 0;
    let subTotal = Number(Record.invoice_sub_total);
    discount_value = Number(discount_value);
    if (Record.discount_type === "6") {
      invoice_discount = (discount_value / 100) * subTotal;
    } else if (Record.discount_type === "5") {
      invoice_discount = discount_value;
    }
    console.log("invoice_discount", invoice_discount);

    handleFieldChange("invoice_discount", invoice_discount.toFixed(2));
    handleFieldChange(
      "invoice_total",
      (subTotal - invoice_discount).toFixed(2)
    );
  }
  function HandleDiscountType(discountType) {
    let discountValue = 0;
    let discount_value = Number(Record.discount_value);
    let subTotal = Number(Record.invoice_sub_total);

    if (discountType === "6") {
      discountValue = (discount_value / 100) * subTotal;
    } else if (discountType === "5") {
      discountValue = discount_value;
    } else {
      discountValue = 0;
    }
    console.log("HandleDiscountType", discountValue);

    handleFieldChange("invoice_discount", discountValue?.toFixed(2));
    handleFieldChange("invoice_total", (subTotal - discountValue).toFixed(2));
  }
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    let attachment = Record.attachment || [];
    UploadFile(attachment_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment", [...attachment, res.data]);
      }
    });
  }

  // calculate total value
  function TotalCalculation(items = Record.items) {
    let subTotal = 0;
    let discountValue = 0;
    let Total = 0;
    let discount_value = Number(Record.discount_value);

    items.map((item) => {
      if (item.amount !== "" && item.amount !== undefined) {
        subTotal += Number(item.amount);
      }
    });

    discountValue = (discount_value / 100) * subTotal;
    Total = subTotal - discountValue;
    handleFieldChange("invoice_sub_total", subTotal.toFixed(2));
    handleFieldChange("invoice_discount", discountValue.toFixed(2));
    handleFieldChange("invoice_total", Total.toFixed(2));
  }

  // get specific record in list
  function GetRecordFromList(List = [], value) {
    List = List.filter((item) => item.value == value);
    if (List.length > 0) {
      return List[0];
    } else {
      return undefined;
    }
  }

  function HandleStates(state = "Draft") {
    let Stages_list = [...props.Stages.Stages_list];
    for (let i = 0; i < Stages_list.length; i++) {
      if (Stages_list[i].Name.toLowerCase() === state.toLowerCase()) {
        Stages_list[i].selected = true;
        break;
      } else {
        Stages_list[i].selected = false;
        Stages_list[i].completed = true;
      }
    }
    HandlePropsChange("Stages", { ...props.Stages, Stages_list });
  }

  return (
    <AccountsLayout HeaderTitle={"Invoice Form"}>
      <div className="flex flex-col h-full text-[12px]">
        <FormHeader
          State="Draft"
          FormName="Invoice"
          FormNumber={Record.invoice_no}
          Actions={[
            {
              ButtonName: "Submitted",
              type: "primary",
              onClick: Submit,
            },
            {
              ButtonName: "Cancel",
              onClick: () => {
                navigate(-1);
              },
            },
          ]}
        />
        <FormStage Stages={props.Stages.Stages_list} />
        <div className="flex bg-secondary flex-col items-center flex-grow rounded-lg shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] overflow-auto">
          <div className="grid grid-cols-2 gap-2 w-[80%] mt-5">
            <div // customer_id
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.customer_id.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Customer
                {props.customer_id.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"customer_id"}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.customer_id.mandatory && props.customer_id.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("customer_id", event.target.value);
                  HandleCustomer(event.target.value);
                  TotalCalculation();
                }}
                value={Record.customer_id}
                disabled={props.customer_id.readonly}
                aria-placeholder="please select sam"
              >
                {props.customer_id.customer_id_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            <div // invoice_date
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.invoice_date.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Invoice Date
                {props.invoice_date.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
                  (props.invoice_date.mandatory && props.invoice_date.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"invoice_date"}
                type="date"
                value={Record.invoice_date.split("T")[0]}
                disabled={props.invoice_date.readonly}
                onChange={(event) => {
                  handleFieldChange(
                    "invoice_date",
                    event.target.value + "T00:00:00Z"
                  );
                }}
              />
            </div>

            <div // delivery_address
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.delivery_address.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Delivery Address
                {props.delivery_address.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"delivery_address"}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.delivery_address.mandatory &&
                  props.delivery_address.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("delivery_address", event.target.value);
                }}
                value={Record.delivery_address}
                disabled={props.delivery_address.readonly}
                aria-placeholder="please select sam"
              >
                {props.delivery_address.delivery_address_list.map(
                  (item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                )}
              </select>
            </div>

            <div // invoice_due_date
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.invoice_due_date.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Due Date
                {props.invoice_due_date.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
                  (props.invoice_due_date.mandatory &&
                  props.invoice_due_date.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"invoice_due_date"}
                type="date"
                value={Record.invoice_due_date.split("T")[0]}
                disabled={props.invoice_due_date.readonly}
                onChange={(event) => {
                  handleFieldChange(
                    "invoice_due_date",
                    event.target.value + "T00:00:00Z"
                  );
                }}
              />
            </div>
          </div>
          <h1
            className={
              " text-[16px] font-semibold w-[95%] px-5 mt-2 gap-1 flex  relative "
            }
          >
            Projects
          </h1>
          <div // items
            className={
              " text-[14px] w-[95%] px-3 flex-col items-center gap-1 flex py-2 relative "
            }
          >
            <div className="flex justify-around w-full gap-2 border-y border-Old_Silver border-opacity-35">
              <div className="text-[14px] w-full px-2 py-1">Projects</div>
              <div className="text-[14px] w-full px-2 py-1">Account</div>
              <div className="text-[14px] w-full px-2 py-1">
                TradeMark/Remarks
              </div>
              <div className="text-[14px] w-full px-2 py-1">Amount</div>
              <span className=" mr-4 rounded-lg  cursor-pointer"></span>
            </div>
            {Record.items.map((item, index) => (
              <div
                className="flex justify-around w-full gap-2 border-b border-Old_Silver border-opacity-35"
                key={index}
              >
                <select // items.Group
                  id={"items"}
                  className={
                    " w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-35 outline-none " +
                    (props.items.mandatory && props.items.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  onChange={(event) => {
                    Handleproject_id(event.target.value, index);
                  }}
                  value={item.project_id}
                  disabled={props.items.readonly}
                >
                  {props.items.project_list.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                <select // items.salesAccount
                  id={"items"}
                  className={
                    " w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                    (props.items.mandatory && props.items.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  onChange={(event) => {
                    Handlecharts_acc_id(event.target.value, index);
                  }}
                  value={item.charts_acc_id}
                  disabled={props.items.readonly}
                >
                  {props.items.salesOrder_list.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>

                <input // items.Quantity
                  className={
                    "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                    (props.items.mandatory && props.items.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"items"}
                  type="text"
                  value={item.description}
                  disabled={props.items.readonly}
                  onChange={(event) => {
                    let items = Record.items;
                    items[index].description = event.target.value;
                    handleFieldChange("items", items);
                    TotalCalculation();
                  }}
                />

                <input // items.Amount
                  className={
                    "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                    (props.items.mandatory && props.items.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"items"}
                  type="number"
                  value={item.amount}
                  disabled={props.items.readonly}
                  onChange={(event) => {
                    let items = Record.items;
                    items[index].amount = event.target.value;
                    handleFieldChange("items", items);
                    TotalCalculation();
                  }}
                />
                <span
                  onClick={() => {
                    let items = Record.items;
                    items = items.filter(
                      (itemsitem, itemsindex) => itemsindex != index
                    );
                    handleFieldChange("items", items);
                    TotalCalculation(items);
                  }}
                  className="border h-fit w-fit p-1 rounded-lg hover:bg-tertiary cursor-pointer"
                >
                  X
                </span>
              </div>
            ))}
            <div className="flex px-2 pb-2 w-full gap-2 border-b border-Old_Silver border-opacity-35">
              <button
                className={"text-Ocean_Blue hover:drop-shadow-2xl"}
                onClick={() => {
                  let items = Record.items;
                  items.push({
                    project_id: "",
                    charts_acc_id: "",
                    description: "",
                    amount: "",
                  });

                  handleFieldChange("items", items);
                }}
              >
                Add New Line
              </button>
            </div>
          </div>
          {/* {Record.expense_id.length != 0 && ( */}
          <h1
            className={
              " text-[16px] font-semibold w-[95%] px-5 mt-2 gap-1 flex  relative "
            }
          >
            Expense
          </h1>
          {/* )} */}
          {/* {Record.expense_id.length != 0 && ( */}
          <div // expense_id
            className={
              " text-[14px] w-[95%] px-3 flex-col items-center gap-1 flex py-2 relative "
            }
          >
            <div className="flex justify-around w-full gap-2 border-y border-Old_Silver border-opacity-35">
              <div className="text-[14px] w-full px-2 py-1">Expense</div>
              <div className="text-[14px] w-full px-2 py-1">Description</div>
              <div className="text-[14px] w-full px-2 py-1">Amount</div>
              <span className=" mr-4 rounded-lg  cursor-pointer"></span>
            </div>
            {Record.expense_id.map((item, index) => (
              <div
                className="flex justify-around w-full gap-2 border-b border-Old_Silver border-opacity-35"
                key={index}
              >
                <input // items.Quantity
                  className={
                    "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                    (props.items.mandatory && props.items.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"items"}
                  type="text"
                  value={item.name}
                  disabled={props.items.readonly}
                  onChange={(event) => {
                    let items = Record.items;
                    items[index].description = event.target.value;
                    handleFieldChange("items", items);
                    TotalCalculation();
                  }}
                />

                <input // items.Quantity
                  className={
                    "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                    (props.items.mandatory && props.items.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"items"}
                  type="text"
                  value={item.description}
                  disabled={props.items.readonly}
                  onChange={(event) => {
                    let items = Record.items;
                    items[index].description = event.target.value;
                    handleFieldChange("items", items);
                    TotalCalculation();
                  }}
                />

                <input // items.Amount
                  className={
                    "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                    (props.items.mandatory && props.items.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"items"}
                  type="number"
                  value={item.amount}
                  disabled={props.items.readonly}
                  onChange={(event) => {
                    let items = Record.items;
                    items[index].amount = event.target.value;
                    handleFieldChange("items", items);
                    TotalCalculation();
                  }}
                />
                <span
                  onClick={() => {
                    let items = Record.items;
                    items = items.filter(
                      (itemsitem, itemsindex) => itemsindex != index
                    );
                    handleFieldChange("items", items);
                    TotalCalculation(items);
                  }}
                  className="border h-fit w-fit p-1 rounded-lg hover:bg-tertiary cursor-pointer"
                >
                  X
                </span>
              </div>
            ))}
            {/* <div className="flex px-2 pb-2 w-full gap-2 border-b border-Old_Silver border-opacity-35">
              <button
                className={"text-Ocean_Blue hover:drop-shadow-2xl"}
                onClick={() => {
                  let expense_id = Record.expense_id;
                  expense_id.push();

                  handleFieldChange("expense_id", expense_id);
                }}
              >
                Add New Line
              </button>
            </div> */}
          </div>
          {/* )} */}

          <div // attachments
            className={
              " text-[14px] w-[95%] px-5 flex-col gap-1 flex py-2 relative "
            }
          >
            <h3 className="text-[14px] ">Attachments</h3>
            <div // attachment
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.attachment.hidden ? " hidden " : " flex ")
              }
            >
              <button
                className="border px-2 py-1 rounded-lg flex gap-1 items-center"
                onClick={() => {
                  document.getElementById("FileInput").click();
                }}
              >
                <MdOutlineFileUpload className="text-[#20b25b] text-[18px] font-bold" />
                Upload File
              </button>
              {Record.attachment.map((attached, index) => (
                <button
                  key={index}
                  className="cursor-pointer hover:text-[#3f4fff] hover:bg-[#2a3cff] hover:bg-opacity-15 px-2 py-1 rounded-lg flex items-center gap-1 group transition-all duration-100"
                  target="_blank"
                >
                  <FaRegEye
                    className="group-hover:block hidden transition-all duration-75 hover:bg-[#5ae271] rounded-full"
                    onClick={() => {
                      store.navtotab(
                        baseurl + "/files/preview?key=" + attached
                      );
                    }}
                  />
                  {attached.replaceAll("Journal_", "")}
                  <IoIosCloseCircleOutline
                    className="group-hover:block hidden transition-all duration-75 z-50 hover:bg-red  rounded-full"
                    onClick={() => {
                      let attachment = Record.attachment || [];
                      attachment = attachment.filter(
                        (item, itemindex) => itemindex != index
                      );
                      handleFieldChange("attachment", [...attachment]);
                    }}
                  />
                </button>
              ))}
              <input
                type="file"
                onChange={HandleAttachement}
                id="FileInput"
                className="hidden"
              />
            </div>
          </div>
          <div className="flex flex-grow w-[95%] pb-2">
            <div className="w-[45%]">
              <div // invoice_customer_notes
                className={
                  "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1  " +
                  (props.invoice_customer_notes.hidden ? " hidden " : " flex ")
                }
              >
                <textarea
                  className={
                    "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                    (props.invoice_customer_notes.mandatory &&
                    props.invoice_customer_notes.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"invoice_customer_notes"}
                  value={Record.invoice_customer_notes}
                  disabled={props.invoice_customer_notes.readonly}
                  onChange={(event) => {
                    if (event.target.value.length > 7) {
                      handleFieldChange(
                        "invoice_customer_notes",
                        event.target.value
                      );
                    } else {
                      handleFieldChange("invoice_customer_notes", "Notes: ");
                    }
                  }}
                />
              </div>
              <div // invoice_terms_and_conditions
                className={
                  "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1  " +
                  (props.invoice_terms_and_conditions.hidden
                    ? " hidden "
                    : " flex ")
                }
              >
                <textarea
                  className={
                    "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                    (props.invoice_terms_and_conditions.mandatory &&
                    props.invoice_terms_and_conditions.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"invoice_terms_and_conditions"}
                  value={Record.invoice_terms_and_conditions}
                  disabled={props.invoice_terms_and_conditions.readonly}
                  onChange={(event) => {
                    if (event.target.value.length > 20) {
                      handleFieldChange(
                        "invoice_terms_and_conditions",
                        event.target.value
                      );
                    } else {
                      handleFieldChange(
                        "invoice_terms_and_conditions",
                        "Terms & Conditions: "
                      );
                    }
                  }}
                />
              </div>
            </div>
            <div className="w-[55%] flex flex-col gap-3">
              <div className="flex items-center justify-between px-9 border-b border-Old_Silver border-opacity-35">
                <div className="text-[14px] text-Old_Silver ">
                  {"Sub Total"}
                </div>
                <div className=" w-[200px] text-[16px] font-semibold px-2 py-1 border-Old_Silver ">
                  {Record.invoice_sub_total}
                </div>
              </div>

              <div className="flex items-center justify-between px-9 border-b border-Old_Silver border-opacity-35">
                <div className="text-[14px] text-Old_Silver ">
                  {"Discounts"}
                </div>
                <div // invoice_discount
                  className={
                    "flex text-[14px] w-[210px] h-full px-3 flex-row items-center gap-3 col-span-1 " +
                    (props.invoice_discount.hidden ? " hidden " : " flex ")
                  }
                >
                  <input
                    className={
                      " w-full px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none" +
                      (props.discount_value.mandatory &&
                      props.discount_value.show
                        ? " rounded-2xl border-2 border-[#ff2828] "
                        : "")
                    }
                    id={"discount_value"}
                    type="number"
                    value={Record.discount_value}
                    disabled={props.discount_value.readonly}
                    onChange={(event) => {
                      handleFieldChange("discount_value", event.target.value);
                      HandleDiscountValue(event.target.value);
                    }}
                  />
                  <select
                    id={"discount"}
                    className={
                      " w-full px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none" +
                      (props.discount_type.mandatory && props.discount_type.show
                        ? " rounded-2xl border-2 border-[#ff2828] "
                        : "")
                    }
                    onChange={(event) => {
                      handleFieldChange("discount_type", event.target.value);
                      HandleDiscountType(event.target.value);
                    }}
                    value={Record.discount_type}
                    disabled={props.discount_type.readonly}
                  >
                    {props.discount_type.discount_type_list.map(
                      (item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className=" w-[200px] text-[16px] font-semibold px-2 py-1 border-Old_Silver ">
                  {(Record.invoice_discount == "0" ||
                  Record.invoice_discount == "0.00"
                    ? ""
                    : "-") + Record.invoice_discount}
                </div>
              </div>

              <div className="flex items-center justify-between px-9 border-b border-Old_Silver border-opacity-35">
                <div className="text-[14px] text-Old_Silver ">
                  {"Total (₹)"}
                </div>
                <div className=" w-[200px] text-[16px] font-semibold px-2 py-1 border-Old_Silver ">
                  {Record.invoice_total}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default InvoiceForm;
